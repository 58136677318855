import { ThemeProvider, createTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import AnimatedCursor from "react-animated-cursor";
import {
  useLocation
} from "react-router-dom";
import Header from "../header/Header";
import "./App.css";
import AppRoutes from "./routes";
import BgAnim from "./bgAnim/BgAnim";

// ScrollToTop
function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
// const ScrollToTop = withRouter(_ScrollToTop); 
const ScrollToTop = _ScrollToTop;

export default function App() {
  // 
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark');

  // MUI theme
  const mui_theme = createTheme({
    palette: {
      mode: theme,
    },
    typography:{
      fontFamily: '"Noto Serif TC", sans-serif', 
      body1: {
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
        color: 'var(--text-color)'
      }
    },
  });
  
  return (
    // <BrowserRouter basename={process.env.PUBLIC_URL}> // is defined in index.js
    <ThemeProvider theme={mui_theme}>
      <div className="cursor__dot">
        <AnimatedCursor
          innerSize={15}
          outerSize={15}
          color="255, 255 ,255"
          outerAlpha={0.4}
          innerScale={0.7}
          outerScale={5}
        />
      </div>
      <ScrollToTop>   {/* auto scroll to top when switching page */}
        <Header theme={theme} setTheme={setTheme} />
        <BgAnim />
        <AppRoutes />
      </ScrollToTop>
    </ThemeProvider>
    // </BrowserRouter>
  );
}
