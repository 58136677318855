import { Alert } from '@mui/material'
import React from 'react'
import SimplePage from '../_template/SimplePage'
import FileList from './FileList'
import UploadSelect from './UploadSelect'

const FileDrive = () => {
  

  return (
    <SimplePage title={"檔案操作"} titleEn="Drive">
      <Alert severity='warning'>你必須登入且取得權限才能繼續。</Alert>

      <h2>Upload New File(s)</h2>

      <UploadSelect />

      <h2>Uploads</h2>
      <FileList />
      
    </SimplePage>
  )
}

export default FileDrive