import { Delete } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import MDEditor from "@uiw/react-md-editor";
import axios from 'axios';
import { useFormik } from 'formik';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthManager from '../../managers/AuthManager';
import SimplePage from '../_template/SimplePage';

const PortfolioItemEdit = () => {
  const initalData = useLocation().state?.data
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      Id: initalData?.id ?? '',
      Title: initalData?.title ?? '',
      Genre: initalData?.genre ?? '',
      Tags: initalData?.tags ?? '',
      PreviewImageUrl: initalData?.previewImageUrl ?? '',
      Index: initalData?.index ?? Math.floor(new Date().getTime()/1000),
      Content: initalData?.content ?? 'Support `markdown`!\n\n### 相關連結\n- 專案連結：https://jcxyis.com/\n- 原始碼：https://github.com/JCxYIS',
    },
		onSubmit: async (values) => {			
			// await new Promise((resolve) => setTimeout(resolve, 500));
			// alert(JSON.stringify(values, null, 2));
			try {
				if (initalData)
          await axios.put('/api/portfolio', values)
        else
          await axios.post('/api/portfolio', values)

        navigate('/portfolio')
			}
			catch(err) {
				console.error(err)
			}
		},
  })

  if(!AuthManager.isRole('Manager')) {
    return navigate('/403')
  }

  return (
    <SimplePage title={initalData ? '編輯':'新增'} >
      {initalData ? <Button
        color='error'
        variant='outlined'
        onClick={()=>{
          if(window.confirm('真的要刪除嗎？'))
            axios.delete('/api/portfolio/'+initalData.Id).then(()=>navigate('/portfolio'))
        }}
        startIcon={<Delete />}
        sx={{marginBottom: 2}}
        >Delete
      </Button>:''}
      <form onSubmit={formik.handleSubmit} /* data-color-mode="light" */>
        <Stack spacing={2}>
          {
          //['Id', 'Title', 'Genre', 'PreviewImageUrl', /*'Content,'*/ 'Index' ]
          Object.keys(formik.initialValues).map(s =>
            <TextField
              key={s}
              name={s}
              label={s}
              value={formik.values[s]}
              onChange={formik.handleChange}
              // error={formik.touched.username && Boolean(formik.errors.username)}
              // helperText={formik.touched.username && formik.errors.username}
              disabled={s === 'Id'}
              />
          )}          
          <MDEditor 
            height='69vh' 
            value={formik.values.Content} 
            onChange={s => formik.setFieldValue('Content', s)}
          />
          <Button type='submit' variant='contained' size='large'>{initalData ? 'Update!':'Create!'}</Button>
          <br/>
        </Stack>

      </form>
    </SimplePage>
  )
}

export default PortfolioItemEdit