import React, { useEffect, useState } from "react";
import "./Home.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import Typewriter from "typewriter-effect";
import { introData, meta } from "../../content_option";
import { Link } from "react-router-dom";

export const Home = () => {
     

  const [quote, setQuote] = useState(introData.quotes[0])
  const [quoteHtml, setQuoteHtml] = useState()

  /** pick a new quote */
  const pickNewQuote = ()=> introData.quotes[Math.floor(Math.random()*introData.quotes.length)]; 

  useEffect(()=>{
    // make à new quote
    setQuoteHtml(
      <p 
        dangerouslySetInnerHTML={{__html: quote }}
        style={{ whiteSpace: 'pre-wrap' }}
      >
      </p>)
      
      resetCssAnim("title-main-title")
      resetCssAnim("title-main-quote")
  }, [quote])

  function resetCssAnim(className) {
    // document.getElementsByClassName("")[0].style.webkitAnimationPlayState = "running";
    let quote_container = document.getElementsByClassName(className)[0]
    quote_container.classList.remove(className)
    void quote_container.offsetWidth;  // magic
    quote_container.classList.add(className)
  }


  return (
    <HelmetProvider>
      <section id="home" className="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <div className="intro_sec d-block d-lg-flex align-items-center ">
          {/* <div
            className="h_bg-image order-1 order-lg-2 h-100 "
            style={{ backgroundImage: `url(${introdata.your_img_url})` }}
          ></div> */}
          <div className="text order-2 order-lg-1 h-100 d-lg-flex justify-content-center">
            <div className="intro mx-auto">
              <h1 className="title-main-title mb-1x">
                {introData.title}
                <span style={{ "--var": 1 }}>J</span>
                <span style={{ "--var": 2 }}>C</span>
                <span style={{ "--var": 3 }}>x</span>
                <span style={{ "--var": 4 }}>Y</span>
                <span style={{ "--var": 5 }}>I</span>
                <span style={{ "--var": 6 }}>S</span>                
              </h1>
              {/* <h1 className="fluidz-48 mb-1x">
                <Typewriter
                  options={{
                    strings: introData.quotes,
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 10,
                  }}
                /> 
              </h1> */}
              <div className="title-main-quote" onClick={()=>{setQuote(pickNewQuote());}}>
                  {quoteHtml}
                  {/* <p style={{ whiteSpace: 'pre-wrap' }}>{introdata.quotes[Math.floor(Math.random()*introdata.quotes.length)]}</p> */}
              </div>
              <p className="mb-1x">{introData.description}</p>
              <div className="intro_btn-action pb-5">
                <Link to="/about" className="text_2">
                  <div id="button_p" className="ac_btn btn ">
                    About me
                    <div className="ring one"></div>
                    <div className="ring two"></div>
                    <div className="ring three"></div>
                  </div>
                </Link>
                <Link to="/contact">
                  <div id="button_h" className="ac_btn btn">
                    Contact
                    <div className="ring one"></div>
                    <div className="ring two"></div>
                    <div className="ring three"></div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section id="bg_particles"></section> */}
    </HelmetProvider>
  );  
};
