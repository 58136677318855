import React, { useRef } from 'react';
import { useInViewport } from '../../hooks/useInViewport';
import './image.css';

const Image = ({
  src,
  alt,
  width,
  height,
  ...props
}) => {

  const ref = useRef();
  const inViewPort = useInViewport(ref, false, { rootMargin: `0px 0px 0px 0px` });  

  return (
    <div
      className='image-container'
      data-visible={inViewPort}
      ref={ref}
    >
      <img 
        className='image-element'
        src={src}
        alt={alt}
        width={width}
        height={height}
        loading='lazy' 
        {...props} />      
      {/* <Skeleton variant="rectangular" width={} height={} /> */}
    </div>
  )
}

export default Image