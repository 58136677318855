import { Button, FormControl, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'
import { Row } from 'react-bootstrap'
import ApiManager from '../../managers/ApiManager'
import SimplePage from '../_template/SimplePage'

export const KokonaStamp = () => {


  const [score, setscore] = React.useState(Math.round(Math.random() * 100))
  const [face, setFace] = React.useState(Math.round(Math.random() * 2))
  const [imgSrcApi, setImgSrcApi] = React.useState('')

  const SvgUrlToPng = (svgUrl, callback) =>  {
    // 放一個 img 偽元素來載入 svgUrl
    const tmpSvgContainer = document.createElement('img');    
    tmpSvgContainer.style.position = 'absolute';
    tmpSvgContainer.style.left = '48763vw';
    if(ApiManager.IS_DEV_SERVER) {
      tmpSvgContainer.crossOrigin = 'anonymous';
    }
    document.body.appendChild(tmpSvgContainer);

    tmpSvgContainer.onload = function () {
        // onload 後傳給 canvas (不須 render 出來) 來轉換成 png (base64)，再把剛剛的 img 偽元素移除
        const canvas = document.createElement('canvas');
        canvas.width = tmpSvgContainer.clientWidth;
        canvas.height = tmpSvgContainer.clientHeight;
        const canvasCtx = canvas.getContext('2d');
        canvasCtx.drawImage(tmpSvgContainer, 0, 0);
        const imgData = canvas.toDataURL('image/png');
        document.body.removeChild(tmpSvgContainer);
        callback(imgData);
        // document.body.removeChild(imgPreview);
    };
    tmpSvgContainer.src = svgUrl;
  }

  const updateImg = () => {
    // get image src
    SvgUrlToPng(`${ApiManager.API_HOST_URL}/api/badges/kokonastamp?score=${score}&face=${face}`, (data) => {
      setImgSrcApi(data)
    })
  }

  return (
    <SimplePage title='ココナ的印章產生器 (API)' titleEn='Kokona Stamp Generator (API)' desc='Sunohara Kokona(春原 ココナ) 💯 stamp'>
      <Row>
        <span>Preview</span>
        <div className="col-sm-4">
          <img src={imgSrcApi} alt="ココナ的印章" style={{ width: "100%", height: "100%"/*, maxHeight: "35vh"*/ }} />
        </div>

        <div className="col-sm-1"></div>

        <FormControl className="col-sm-6 col-xl-4 my-auto">
          {/* <label htmlFor="Score">Score</label> */}
          <TextField
            label='Score' 
            type="number" 
            id="Score" 
            min="0" 
            max="999" 
            value={score} 
            defaultValue={score}
            onChange={(event) => { setscore(event.target.valueAsNumber) }} />
          <br />
          {/* <InputLabel id="face-label">Face</InputLabel> */}
          <Select           
            id="face" 
            // labelId="face-label"
            // label="Face"
            value={face} 
            onChange={(event) => { setFace(event.target.value) }}>
            <MenuItem value="0">Happy (Original)</MenuItem>
            <MenuItem value="1">Smile</MenuItem>
            <MenuItem value="2">Angry</MenuItem>
          </Select>
          <br />
          <Button 
            variant='contained' 
            className="mt-3" 
            size='large'
            onClick={() => { updateImg() }}>Generate</Button>
        </FormControl>
      </Row>
    </SimplePage>
  )
}
