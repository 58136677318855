import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import "./BgAnim.css"
import anime from 'animejs/lib/anime.es'
// import { useTheme } from '@mui/material';

function BgAnimCubeFloat() {
  const poolCount = 40;
  const bgColors = ['#a4ddfb', '#f57da0', '#fad4f5', "#f9e39a", "#c51d51"]

  const MyCube = ({i}) => {
    const animRef = useRef()
    const animObjRef = useRef()

    const route = document.location.pathname;
    const theme = document.documentElement.getAttribute("data-theme");

    const newConfig = useCallback((isFirst)=>{ return {
      x: Math.random()*window.outerWidth * 0.9,
      y: Math.random()*window.innerHeight * 0.9,
      size: route === '/' ? 20+Math.random()*50 : 0,
      delay: isFirst ? 5000/poolCount*i : 0,
      color: theme === 'light' ? bgColors[Math.floor(Math.random()*bgColors.length)] : 'white'
    }}, [route, theme, i]);    

    const [config, setConfig] = useState(newConfig(true))
    // const config = useMemo(()=>newConfig(), [newConfig])

    useEffect(()=>{
      if(animRef.current) {
        // when reference already exist: reinit anim
        animRef.current.pause()
        animRef.current.seek(0)
      }
      
      // const cubes = document.querySelectorAll('.bgAnim rect')
      animRef.current = anime({
        targets: animObjRef.current,
        easing: 'linear',
        duration: 5000,
        delay: config.delay,
        // loop: true,
        // keyframes: [
        //   { opacity: 1 },  // 33%
        //   { opacity: 1 },  // 66%
        //   { opacity: 0 },  // 100%
        // ],
        translateY: config.size*-3, 
        rotate: 360, 
        scale: [0,config.size],  //  0 -> 1,
        opacity: [1,1,1,0], 
        complete: (_) => {
          setConfig(newConfig(false))
        }
      })
    }, [config, newConfig])

    return (<div 
      ref={animObjRef}
      width={10} 
      height={10}  
      style={{
        backgroundColor: config.color, 
        position: 'absolute', 
        top: config.y, 
        left: config.x, 
        width: 1, 
        height: 1,
      }} />)
  }; // end of MyCube


  const MyCubesMemo = useMemo(()=>{
    console.log('BgAnimCubeFloat Rerender')
    return Array.from({length: poolCount}).map((_, i) => <MyCube key={i} i={i} />)
  }, [poolCount]);   // useMemo to prevent reinit on rerender;  
  console.log('BgAnimCubeFloat return')
  
  return (
    <section className='bgAnim' >
      {MyCubesMemo}
    </section>)
}


export default BgAnimCubeFloat