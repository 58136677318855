import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import axios from 'axios'
import React, { useEffect } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ApiManager from '../../managers/ApiManager';
import { Badge, Tooltip } from '@mui/material';

const FileList = () => {
  const [fileList, setFileList] = React.useState([])

  const update_files = async () => {
    let data = await axios.get('/api/files')
    setFileList(data.data)
  }

  useEffect(() => {
    update_files()
  }, [])

  const columns = [
    // { field: 'id', headerName: 'ID', width: 50 },
    { field: 'file', headerName: 'File Name', width: 500 },
    { field: 'create', headerName: 'Last Changed', width: 300 },
    {
      field: 'actions', headerName: 'Actions', type: 'actions', width: 120,
      getActions: (params) => [
        <Tooltip title='Open'>
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            onClick={() => window.open(ApiManager.API_HOST_URL + '/uploads/' + params.id)}
          // showInMenu
          />
        </Tooltip>,
        <Tooltip title='Open (API)'>
          <Badge badgeContent='API' >
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              onClick={() => window.open(ApiManager.API_HOST_URL + '/api/files/' + params.id)}
            />
          </Badge>
        </Tooltip>,
        <Tooltip title='Delete'>
          <GridActionsCellItem
            icon={<DeleteIcon />}
            onClick={async () => {
              await axios.delete('/api/files/' + params.id)
              await update_files()
            }}
          />
        </Tooltip>,
      ],
    }
  ];


  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={fileList}
        columns={columns}
        getRowId={(row) => row.file}
        pageSize={5}
        rowsPerPageOptions={[5]}
      // checkboxSelection

      />
    </div>
  )
}

export default FileList