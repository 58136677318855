import React from 'react'
import { changelog } from '../../content_option'

const Changelog = () => {  

  return changelog.map((item, i) => 
    <div key={i}>
      <h4>{item.version}</h4>
      <ul>
        {item.content.map((content, j) => <li key={j}>{content}</li>)}
      </ul>
    </div>
  )
}

export default Changelog