import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ErrorPage } from "../pages/ErrorPage/ErrorPage";
import { About } from "../pages/about/About";
import Login from "../pages/auth/Login";
import Logout from "../pages/auth/Logout";
import { Chat } from "../pages/chat/Chat";
import { ContactUs } from "../pages/contact/Contact";
import FileDrive from "../pages/file/FileDrive";
import Fork from "../pages/fork/Fork";
import { Home } from "../pages/home/Home";
import { KokonaStamp } from "../pages/kokonastamp/KokonaStamp";
import { Portfolio } from "../pages/portfolio/Portfolio";
import PortfolioItemEdit from "../pages/portfolio/PortfolioItemEdit";
import { Test } from "../pages/test/Test";
import UserPage from "../pages/auth/UserPage";

const AnimatedSwitch = () => {
  const location = useLocation();
  // console.log(location.key)

  return (
  <TransitionGroup component="main">
    <CSSTransition
      key={location.key}
      timeout={{
        enter: 400,
        //exit: 400,  // TODO find a way to 保存原本 route 的狀態，不然切畫面會顯示 2 個同樣的。先刪掉 exit 動畫
      }}
      classNames="page"  // will get page-* classes
      unmountOnExit
    >
      <Routes>
        {/* MAIN */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/portfolio" element={<Portfolio />} />
        <Route exact path="/portfolio/edit" element={<PortfolioItemEdit />} />
        {/* <Route exact path="/portfolio/:id" element={<PortfolioItem />} /> */}
        <Route exact path="/contact" element={<ContactUs />} />
        <Route exact path="/test" element={<Test />} />

        {/* Auth */}
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Login isSignUp />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/user" element={<UserPage /> } />

        {/* ETC */}
        <Route path="/fork" element={<Fork />} />
        <Route path="/kokonastamp" element={<KokonaStamp />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/drive" element={<FileDrive />} />

        {/* Http Errors lol */}
        <Route path="/403" element={<ErrorPage code={403} desc={<>403 Forbidden...<br/>You may need to <Link to='/login'>login</Link> first</>} />} />        
        <Route path="/404" element={<ErrorPage code={404} desc={'404 Not found...'} />} />        

        {/* Fallback */}
        <Route path="*" element={<ErrorPage code={404} desc={'404 Not found...'} />} />   
        {/* <Navigate to="/404" /> */}
        {/* <Route path="/" component={Home} /> */}
      </Routes>
    </CSSTransition>
  </TransitionGroup>
)};

function AppRoutes() {
  return <AnimatedSwitch />
  // return (
  //   <div className="s_c">
  //     <AnimatedSwitch />
  //     <Socialicons />
  //   </div>
  // );
}

export default AppRoutes;
