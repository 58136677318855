import React from 'react';
import { Link } from 'react-router-dom';
import ApiManager from '../../managers/ApiManager';
import SimplePage from '../_template/SimplePage';
import Changelog from './Changelog';
import { version } from '../../content_option';

export const Test = () => {
  return (
    <SimplePage title='開發用頁面' titleEn='Test Page'>
      <blockquote className='my-3 mb-4'>
        <i>Testing fun stuffs in this website. </i><br />
        <i>也許哪天會派上用場...？</i><br />
      </blockquote>      

      {/* Content */}
      <h2>Fun stuffs</h2><hr />
      <h4>Pages</h4>
      <ul>
        <li><Link to="/fork">Fork (CSS)</Link></li>
        <li><Link to="/kokonastamp">ココナ的印章 (SVG)</Link></li>
        <li><Link to="/chat">Simple Chat Room (Socket/SignalR)</Link></li>
        <li><Link to="/login">Auth (Login/Logout)</Link></li>
        <li><Link to="/drive">File Drive (Upload/Download)</Link></li>
        <li><a href={ApiManager.API_HOST_URL+'/swagger'}>Swagger (API Explorer, <i>NOT available for production server</i>)</a></li>
        {/* <li><a href="/api/badges">Badges</a></li> */}
      </ul>

      <h4>APIs</h4>
      <ul>
        <li><a href={ApiManager.API_HOST_URL+'/api/Connection/ip'}>Your IP</a></li>
      </ul>

      <h4>Http error page</h4>
      <ul>
        <li><Link to="/404">404</Link></li>
      </ul>

      <h2>Html</h2><hr />
      某些<ruby>文字<rt>Text</rt></ruby> 
      <p>BE Host: </p>

      <h2>CHANGELOG</h2><hr />
      <p>
        <b>{version}</b>
      </p>
      <Changelog />


      {/* <h2>Animatee icons</h2><hr /> */}
      {/* <div style={{ width: '256px' }}> */}
        {/* <Lottie animationData={loadingLottieJson} autoplay={true} /> */}
        {/* <Lottie animationData={flatten('#39c5bb', loadingLottieJson)} autoplay={true} /> */}
        {/* <Lottie animationData={lottieJson} autoplay={true} /> */}
      {/* </div> */}

      {/* <object type="image/svg+xml" data="/api/badges" alt="Badge" /> */}
      <img className="w-100" src={ApiManager.API_HOST_URL+"/api/badges"} alt="Badge img" style={{ maxWidth: "660px" }} />
      {/*<img className="w-100" src="/api/badges?width=660&height=125" alt="JC's Badge (baha)" style={{ maxWidth: "600px" }} />    */}
    </SimplePage>
  )
}

// export default Test
