import React, { useEffect } from "react";
import { WiMoonAltWaningCrescent4 } from "react-icons/wi";


const Themetoggle = ({theme, setTheme}) => {
  // const [theme, setTheme] = useState();
  
  const themetoggle = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    document.documentElement.setAttribute('data-color-mode', theme);  // @uiw/react-md-editor
    localStorage.setItem('theme', theme ); 
  }, [theme]);

  return (
    <div className="nav_ac" onClick={themetoggle}>
      <WiMoonAltWaningCrescent4 />
    </div>
  );
};

export default Themetoggle;
