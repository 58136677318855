import { Button, Chip, Grid, Stack } from '@mui/material'
import MDEditor from '@uiw/react-md-editor'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AuthManager from '../../managers/AuthManager'

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const PortfolioItemDetail = ({ data, setData }) => {
  // const params = useParams()
  // const id = params.id | -1
  // const [data, setData] = portfolio[id] 
  const navigate = useNavigate(); 

  if(!data)
    return <section className="portfolio-item" />

  return (
    <section className={"portfolio-item" + (data ? ' open' : '')}>
      {/* Head */}
      <Grid container justifyContent="flex-end" sx={{ position: 'fixed' }}>
        {/* Edit Button */}
        {AuthManager.isRole('Manager') ?
          <Button
            onClick={() => { navigate('edit', {state: {data: data}}) }}
            sx={{ fontSize: 40, color: 'inherit' }}>✎
          </Button>
        :''}

        {/* Close button */}
        <Button
          onClick={() => setData(undefined)}
          sx={{ fontSize: 40, color: 'inherit' }}>×
        </Button>        
      </Grid>

      {/* Main */}
      <Grid container sx={{paddingX: 6, paddingY: 4, height: '100%'}} columnSpacing={3}> 
        <Grid item xs={12}>
          <h1>{data.title}</h1>
          <hr />
          {/* Index & Tags */}
          <Stack direction="row" spacing={1}>
            <Chip label={new Date(data.index*1000).toLocaleDateString()} variant='outlined' color='default' />
            <Chip label={data.genre} color='success' />
            {data.tags.split(',').map((tag, i) => {
              return tag ? <Chip key={i} label={tag} /> : ''
            })}
          </Stack>
        </Grid>
        
        {/* Content */}
        <Grid item xs={10} md={7} style={{maxHeight: '80%', overflowY: 'auto', paddingBottom: 100, marginTop: 20}}>
          <MDEditor.Markdown 
            source={data.content} 
            style={{ 
              backgroundColor: 'inherit', 
              fontFamily: 'inherit',                
          }} />
          {/* <p style={{ whiteSpace: 'pre-wrap' }}>{data.desc}</p> */}
          {/* <p>Ref link: <a href={data.Link}>{data.Link}</a></p> */}
        </Grid>

        {/* Preview Img */}
        <Grid item xs={10} md={5}>
          {data.previewImageUrl ?
            <img 
            src={data.previewImageUrl} 
            alt={"Preview pic of "+data.title} 
            loading="lazy" 
            // center
            style={{display: 'flex', justifyContent: 'center', maxWidth: '80%', maxHeight: '80%', objectFit: 'contain', margin: 'auto'}}
            />
            : ''
          }
        </Grid>
      </Grid>
    </section>
  )
}

export default PortfolioItemDetail
