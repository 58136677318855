import axios from "axios";

const AuthManager = {
  /**
   * 拿使用者 token，也可以用於判斷是否登入
   * @returns 
   */
  getToken() {
    return this.getSession()?.token;
  },

  getUsername() {
    return this.getSession()?.username;
  },

  /**
   * 檢查使用者權限 (如果有 Admin 則都可以)
   */
  isRole (role) {
    var roles = AuthManager.getSession()?.userRoles?.split(',');    
    return roles?.includes(role) || roles?.includes('Admin');
  },

  /**
   * 拿使用者相關資料 (token, username, userRoles)
   */
  getSession() {
    let token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const userRoles = localStorage.getItem('userRoles');
    const loginTime = localStorage.getItem('loginTime');

    // if not logged in
    if (!token || !username || !loginTime) {
      return null;
    }

    // 如果登入超過 5 分鐘，重新拿 token
    if(new Date(loginTime) < new Date(new Date().getTime() - 5*60*1000)) {
      this.refreshToken();
    }
    
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return { token, username, userRoles };
  },

  async refreshToken() {
    try {
      const newToken = (await axios.get(`/api/auth/token`)).data
      localStorage.setItem('token', newToken);
      localStorage.setItem('loginTime', new Date().toISOString());
      console.log('Token refreshed')
    }
    catch(err) {
      console.error(err)
      return null;
    }
  },

  /**
   * 登出，把使用者資訊刪掉
   */
  removeSession() {
    localStorage.removeItem('userName');
    localStorage.removeItem('token');
    localStorage.removeItem('usersRoles');
    localStorage.removeItem('loginTime');
    axios.defaults.headers.common['Authorization'] = undefined;
  },

  /**
   * 進行登入，登入成功後會把 token 存到 localStorage；失敗噴 error。
   * @param {*} param0 username & password
   */
  async loginAsync  ({username, password}) {
    // Get token
    const token = (await axios.post(`/api/auth/login`, {username, password})).data;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    localStorage.setItem('token', token);

    await this._fetchUserData(token);
  },  

  /**
   * 使用 OAuth token 登入，需先拿到 token。
   * @param {*} token 
   */
  async loginWithOAuthTokenAsync (oauthProvider, oauthToken) {
    // Get token
    const token = (await axios.post(`/api/auth/loginOAuth`, {oauthProvider, oauthToken})).data;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    localStorage.setItem('token', token);

    await this._fetchUserData(token);    
  },

  /**
   * 拿到 token 後去拿使用者資料，存在 localStorage。
   * @param {*} values 
   */
  async _fetchUserData(values)  {
    // Get user
    const user = (await axios.get('/api/users/me')).data;
    localStorage.setItem('username', user['username']);
    localStorage.setItem('userRoles', user['roles']); 
    localStorage.setItem('loginTime', new Date().toISOString());
  }
}

/* -------------------------------------------------------------------------- */

// 全域設 AJAX Response interceptor，只要用 axios 都會進到這裡
// https://dotblogs.com.tw/wasichris/2020/10/25/223728
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    let statusCode = error.response?.status
    if (statusCode === 401) {
      // 一般不會遇到 401，只有 token 無效、(或是登入失敗)的情況下才會遇到，所以我們把 token 刪掉 reload
      if (axios.defaults.headers.common['Authorization'] !== undefined) {
        AuthManager.removeSession()
        window.location.reload()
      }
      alert('請重新登入')
    }
    else if(statusCode === 403) {
      console.warn('您的權限不足')
    }
    return Promise.reject(error)
  }
)

AuthManager.getSession();

export default AuthManager