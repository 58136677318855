import axios from "axios";

export default class ApiManager {
  /** If you want to call an api **without axios**, use `${ApiManager.API_HOST_URL}/api/...` */
  static API_HOST_URL= 'https://api.jcxyis.com';

  static IS_DEV_SERVER = false;

  static init() {
    this.IS_DEV_SERVER = process.env.NODE_ENV === 'development';

    if(this.IS_DEV_SERVER) {
      this.API_HOST_URL = 'http://localhost:18763';
    }
    axios.defaults.baseURL = this.API_HOST_URL;
  }
}

