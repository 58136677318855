import { Button } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import LoadingScreen from '../../components/LoadingScreen'

const UploadSelect = () => {

  const [files, setFiles] = React.useState([])
  const [loading, setLoading] = useState(false);

  const onFileInputChange = (e) => {
    setFiles(e.target.files)
  }

  const doUpload = async () => {
    var formData = new FormData();
    var files = document.querySelector('#file').files;    
    for (var i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    setLoading(true);
    await axios.post('api/files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    setLoading(false);
    window.location.reload();
  }

  return (
    <div>
      <LoadingScreen open={loading} />
      <div>
        <Button component="label">
          Select Files
          <input id="file" type="file" name="files" multiple /*accept="image/*"*/ onChange={onFileInputChange} hidden />
        </Button>
        <span>Selected {files.length} files</span>
      </div>
      <div hidden={files.length === 0}>
        <Button variant="contained" type="submit" value="Upload" onClick={doUpload} >Upload</Button>
      </div>
    </div>
  )
}

export default UploadSelect