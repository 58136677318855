import { HubConnectionBuilder } from '@microsoft/signalr';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { meta } from '../../content_option';
import ApiManager from '../../managers/ApiManager';

export const Chat = () => {

	const [connection, setConnection] = useState(undefined)
	const [chat, setChat] = useState([]);
	const [input, setInput] = useState({ user: '', message: '' });

	useEffect(() => {
		const newConnection = new HubConnectionBuilder()
			.withUrl(ApiManager.API_HOST_URL+"/hub/chat")
			.withAutomaticReconnect()
			.build()

		setConnection(newConnection)
	}, [])

	useEffect(() => {
		if (connection) {
			connection.start()
				.then(result => console.log('Connected!'))
				.catch(e => console.error('Connection failed: ' + e))

			console.log("Starting connection...")

			// Events
			connection.on('ReceiveMessage', message => {
				setChat(oldChat => [`${new Date().toLocaleString()} | ${message}`].concat(oldChat));
			});
		}
	}, [connection]);

	const sendMessage = async () => {
		// console.log(connection.connectionId)
		if (connection.connectionId) {
			try {
				// console.log("send", input)
				await connection.invoke('SendMessage', input.user, input.message);
			}
			catch (e) {
				console.error('Error sending message:' + e);
			}
		}
		else {
			alert('Not connected to server yet.');
		}
	}

	const handleInput = (e) => {
		setInput({ ...input, [e.target.name]: e.target.value });
	}

	return (
		<HelmetProvider>
			<Container>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Simple ChatRoom | {meta.title}</title>
					<meta name="description" content={meta.description} />
				</Helmet>
				<Row className="mb-3 mt-3">
					<Col lg="8">
						<h1 className="display-4 mb-4">Simple Chat Room</h1>
						<hr className="t_border my-4 ml-0 text-left" />
					</Col>
				</Row>

				{/* Main */}
				<Row>
					<Col lg="12">
						<p>User ID: {connection ? connection.connectionId : ""}</p>
					</Col>
				</Row>

				<Row>
					<Col lg="12">
						<span>User:</span>
						<input 
							className="form-control"
							type="text" 
							name="user" 
							placeholder='Enter your Username'
							value={input.user}
							onChange={handleInput} 
							/>
					</Col>
					<Col lg="12" className="">
						<span>Message:</span>
						<input 
							className="form-control"
							type="text" 
							name="message" 
							placeholder='Enter your Message'
							value={input.message}
							onChange={handleInput} 
						/>
					</Col>
				</Row>

				<Row className='mt-3'>
					<Col lg="12">						
						<Button onClick={sendMessage}>Send</Button>
					</Col>
				</Row>

				<Row>
					<Col lg="12" className="">
						<div className="col-12">
							<ul id="messagesList"></ul>
							{chat.map((message, index) => (
								<li key={index}>{message}</li>
							))}
						</div>
					</Col>
				</Row>
				{/* <Alert
				show={true}
				variant="danger"
				className={`rounded-0 co_alert d-block`}
				onClose=''
				// dismissible
				>Oh no!</Alert> */}
			</Container>
		</HelmetProvider>
	)
}
