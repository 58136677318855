import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AuthManager from '../../managers/AuthManager'
import SimplePage from '../_template/SimplePage'

const Logout = () => {

  const navigate = useNavigate()

  const handleLogout = () => {
    AuthManager.removeSession();
    navigate('/login');
  }

  if(!AuthManager.getToken()) {
    navigate('/login');
    return;
  }

  return (
    <SimplePage title='登出' titleEn='Logout'>
      {/* <div>登出確認</div> */}
      <Dialog open>
        <DialogTitle>確定要登出嗎？</DialogTitle>
        <DialogContent>
          <DialogContentText>
            你將會被登出。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout}>確認</Button>
          <Button onClick={()=>navigate('/')}>取消</Button>
        </DialogActions>
      </Dialog>
    </SimplePage>
  )
}

export default Logout