import React from 'react'
import SimplePage from '../_template/SimplePage'
import AuthManager from '../../managers/AuthManager'
import { Link } from 'react-router-dom'

const UserPage = () => {

  return (
    <SimplePage title='使用者資訊' titleEn='User Info'>
      <h3>歡迎，{AuthManager.getSession().username}</h3>
      <p>你已經登入！</p>

      <p>Login Info</p>
      <ul>
        <li>權限：{AuthManager.getSession().userRoles}</li>
        <li>Token：<code>{AuthManager.getSession().token}</code></li>
      </ul>

      <Link to="/logout">按此登出</Link>
    </SimplePage>)
}

export default UserPage