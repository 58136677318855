import Lottie from "lottie-react";
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import lottieJson from '../../app/lottie_json/thinking.json';
import { meta } from "../../content_option";
import "./ErrorPage.css";


export const ErrorPage = ({code=404, desc='Some error occured...'}) => {
  return (
    <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{code.toString()} | {meta.title}</title>
          <meta name="description" content={'Error page'} />
        </Helmet>
        <div id="container404">
          <div title=":thinking:" >
            <Lottie animationData={lottieJson} autoplay={true} />
          </div>
          <h1 style={{textAlign: 'center'}}>{desc}</h1>
        </div>
    </HelmetProvider>
  )
}
