// import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// import App from './App';
import App from './app/App';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ApiManager from './managers/ApiManager';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');  // process.env.PUBLIC_URL
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>);

// service init
ApiManager.init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyABOWZcA-d12w24b6vQqc6oz95rkpeZob8",
  authDomain: "jc-website-v5.firebaseapp.com",  // Dont fotget to add this domain to firebase > Authentication > Settings > Authorized domains
  projectId: "jc-website-v5",
  storageBucket: "jc-website-v5.appspot.com",
  messagingSenderId: "760724213182",
  appId: "1:760724213182:web:a0a474bd3df1f2daf07def",
  measurementId: "G-DE5TD96D0R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
// const analytics = getAnalytics(app);