import { Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import { Col } from "react-bootstrap";
import Image from "../../components/Image/image";
import {
  aboutData
} from "../../content_option";
import SimplePage from "../_template/SimplePage";
import "./About.css";
import AboutCard from "./AboutCard";

export const About = () => {

  // const scroll = useScroll();

  return (
    <SimplePage title='關於我' titleEn='About Me' desc='Brief introduction'>

      <motion.div>
        <Grid container >
          <Grid item sm={12} lg={6}>
            <Typography variant="h4" gutterBottom>{aboutData.intro.title}</Typography>
            <Typography gutterBottom>{aboutData.intro.aboutMe}</Typography>
          </Grid>
          <Grid item lg={2}>
          </Grid>
          <Grid item lg={4}>
            <Image alt="my-avatar" src={aboutData.avatarImage} width={256} height={256} />
          </Grid>
        </Grid>
      </motion.div>



      {/* Experience */}
      <motion.div className="sec_sp" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} >
        <Typography variant="h4" gutterBottom>Experience</Typography>
        <Col lg="7">
          <table className="table">
            <tbody>
              {aboutData.experience.map((data, i) => {
                return (
                  <tr key={i}>
                    <th>{data.date}</th>
                    <td>{data.where}</td>
                    <td>{data.jobtitle}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      </motion.div>

      {/* School Exp  */}
      {/* <Row className="sec_sp">
        <Col lang="5">
          <h3 className="color_sec py-4">在學經歷</h3>
        </Col>
        <Col lg="7">
          <table className="table caption-top">
            <tbody>
              {about_schools.map((data, i) => {
                return (
                  <tr key={i}>
                    <th scope="row">{data.title}</th>
                    <td>{data.where}</td>
                    <td>{data.date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row> */}

      {/* Skills */}
      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} >
        <Typography variant="h4" gutterBottom>Skills</Typography>
        <Grid container columnGap={3} rowGap={4}>
          {aboutData.skills.map((data, i) => {
            return (
              // <div className="service_ py-3" key={i}>
              //   <h5 className="service__title">{data.title}</h5>
              //   <p className="service_desc">{data.description}</p>
              // </div>
              <Grid item xs={12} md={5} lg={3} key={i} >
                <AboutCard imageUrl={data.image} title={data.title} description={data.description} tags={data.tags} />
              </Grid>
            );
          })}
        </Grid>
      </motion.div>

      {/* Awards */}
      {/* <Row className="sec_sp">
          <Col lang="5">
            <h3 className="color_sec py-4">榮譽獎項</h3>
          </Col>
          <Col lg="7">
          <table className="table caption-top">
              <tbody>
                {about_award.map((data, i) => {
                  return (
                    <tr key={i}>
                      {data.link ?
                        <th scope="row"><a href={data.link}>{data.title}</a></th> :
                        <th scope="row">{data.title}</th>}
                      <td>{data.date}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
        </Row> */}


      {/* Slider */}
      {/*
        <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">Slider</h3>
          </Col>
          <Col lg="7">
            {skills.map((data, i) => {
              return (
                <div key={i}>
                  <h3 className="progress-title">{data.name}</h3>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{
                        width: `${data.value}%`,
                      }}
                    >
                      <div className="progress-value">{data.value}%</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
        */}

    </SimplePage>
  );
};
