import React from "react";
import { Col, Row } from "react-bootstrap";
import { contactConfig, socialprofiles } from "../../content_option";
import "./Contact.css";
import LottieLink from "./LottieLink";

import facebookLottieJson from '../../app/lottie_json/facebook.json';
import githubLottieJson from '../../app/lottie_json/github.json';
import linkedinLottieJson from '../../app/lottie_json/linkedin.json';
import twitterLottieJson from '../../app/lottie_json/twitter.json';
import SimplePage from "../_template/SimplePage";
import ApiManager from "../../managers/ApiManager";

export const ContactUs = () => {
  


  return (
    <SimplePage title="共下來寮" titleEn="Get In touch" desc='Contact me'>

        {/* Lottie Icons */}
        <Row className="sec_sp">
          <Col lg="12" className="">
            {/* <h3 className="color_sec">Get in touch</h3> */}
            <p>{contactConfig.description}</p>
            <div className="socialIconContainer">
              <LottieLink link={socialprofiles.github} animationData={githubLottieJson} />
              <LottieLink link={socialprofiles.facebook} animationData={facebookLottieJson} />
              <LottieLink link={socialprofiles.twitter} animationData={twitterLottieJson} />
              <LottieLink link={socialprofiles.linkedin} animationData={linkedinLottieJson} />
              {/* <Lottie_Link link={socialprofils.email} animationData={emailLottieJson} /> */}
            </div>
          </Col>
        </Row>

        {/* Other links */}
        <Row className="sec_sp">
          <Col lg="5" className="mb-1">
            <h3 className="color_sec py-1">Also checkout...</h3>
            <address>
              <strong>Email: </strong>{" "}
              <a href={`mailto:${contactConfig.YOUR_EMAIL}`}>{contactConfig.YOUR_EMAIL}</a>              
            </address>
            <address>
              <strong>Discord: </strong>{" "}
              <a href={`https://discord.com/users/291015893394128896`}>JCxYIS#6705</a>              
            </address>
            <address>
              <strong>Itch.io: </strong>{" "}
              <a href={`https://jcxyis.itch.io/`}>jcxyis.itch.io</a>              
            </address>
          </Col>
        </Row>
        
        <div className="" style={{maxWidth:'500px'}}>
        <img className="w-100" src={`${ApiManager.API_HOST_URL}/api/badges`} alt="Badge Img" />
        </div>

      
         
    </SimplePage>
  );
};
