import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { meta } from '../../content_option';

const SimplePage = ({ title, titleEn, desc, children }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{titleEn ?? title} | {meta.title}</title>
        <meta name="description" content={desc ?? title ?? titleEn} />
      </Helmet>

      <Container className="pb-4">
        <Row className="mb-3 mt-3">
          <Col lg="12">
            <h1 className="display-5">{title}</h1>  {/* display-4 */}
            <h5 className="mb-4">{titleEn}</h5>
            <hr className="col-12 t_border my-2 ml-0 text-left" />
          </Col>
        </Row>

        {children}

      </Container>

      {/* Loading Bar on top */}
      {/* <div className={false ? "loading-bar" : ""}></div> */}

      {/* <Socialicons /> */}   

    </HelmetProvider>
  )
}

SimplePage.prototypes = {
  title: PropTypes.string.isRequired,
  titleEn: PropTypes.string,
  desc: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default SimplePage