import Lottie from "lottie-react";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import menuIconJson from "../app/lottie_json/menuV4.json";
import Themetoggle from "../components/themetoggle";
import { copyright, logotext } from "../content_option";
import "./Header.css";
import AuthManager from "../managers/AuthManager";

const Header = ({theme, setTheme}) => {
  const [isActive, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
    document.body.classList.toggle("ovhidden");
  };

  // Menu Icon
  const lottieRef = useRef();
  const menuIcon = <Lottie className="lottie-color-override" lottieRef={lottieRef} animationData={menuIconJson} loop="false" autoplay={false} />;

  useEffect(()=>{
    // console.warn("useEffect");
    // flatten('#000000', menuIconJson)
    lottieRef.current.setDirection(isActive ? 1 : -1);
    lottieRef.current.play();
  })

  return (
    <>
      <header className="fixed-top site__header">
        <div className="d-flex align-items-center justify-content-between">
          <Link  className="navbar-brand nav_ac" to="/">
            {logotext}
          </Link>
          <div className="d-flex align-items-center">
          <Themetoggle theme={theme} setTheme={setTheme} />
          <button className="menu_button  nav_ac" onClick={handleToggle} title="Open Menu">
            {menuIcon}
            {/* {!isActive ? <VscClose /> : <VscGrabber />} */}
          </button>
          
          </div>
        </div>

        <div className={`site__navigation ${isActive ? "menu__opend" : ""}`}>
          <div className="bg__menu h-100">
            <div className="menu__wrapper">
              <div className="menu__container p-3">
                <ul className="the_menu">
                  <li className="menu_item ">
                    <Link onClick={handleToggle} to="/" className="my-3">Home</Link>
                  </li>
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="/portfolio" className="my-3">Portfolio</Link>
                  </li>
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="/about" className="my-3">About</Link>
                  </li>
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="/contact" className="my-3">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="menu_footer d-flex flex-column flex-md-row justify-content-between align-items-md-center position-absolute w-100 p-3">
            <div className="d-flex">
              {/* <a href={socialprofils.facebook}>Facebook</a>
              <a href={socialprofils.github}>Github</a>
              <a href={socialprofils.twitter}>Twitter</a> */}
            </div>
            <p className="copyright m-0">
              <Link onClick={handleToggle} to="/login">{AuthManager.getUsername() ?? ''}</Link>
              <Link onClick={handleToggle} to="/test">{copyright}</Link>
            </p>
          </div>
        </div>
      </header>
      <div className="br-top"    opened={isActive ? 'true':''}></div>
      <div className="br-bottom" opened={isActive ? 'true':''}></div>
      <div className="br-left"   opened={isActive ? 'true':''}></div>
      <div className="br-right"  opened={isActive ? 'true':''}></div>
    </>
  );
};

export default Header;
