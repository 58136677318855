import { flatten } from 'lottie-colorify';
import Lottie from "lottie-react";
import React, { useRef } from 'react';

const LottieLink = ({animationData, link}) => {
	const ref = useRef()
	const lottie = <Lottie className='lottie-color-override' lottieRef={ref} animationData={flatten('#39c5bb', animationData)} autoplay={false} />


  return (
    <a className="socialIcon" href={link}  title={link}
			onMouseEnter={()=>{ref.current?.play();}} 
			onMouseLeave={()=>{ref.current?.stop()}}>
        {lottie}
    </a>
  )
}

export default LottieLink