import React, { useCallback, useEffect, useMemo, useState } from 'react'
import BgAnimCubeFloat from './BgAnimCubeFloat'
import { useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';

function BgAnim() {
  const animComponents = useMemo(()=>[
    BgAnimCubeFloat,
  ], []);  // useMemo to prevent reinit on rerender;
  
  const refreshAnimId = useCallback(() => 
    Math.floor(Math.random()*animComponents.length)
  ,[animComponents.length]);  // cache same result until animComponents.length changes (which never happens)

  const [animId, setAnimId] = useState(refreshAnimId())
  const route = useLocation();
  const theme = useTheme();
  useEffect(()=>{    
    if(route.pathname === "/") {
      setAnimId(refreshAnimId())
    }
  }, [route, refreshAnimId])
  
  const AnimComponent = animComponents[animId]

  // return AnimComponent
  return <AnimComponent route={route.pathname} theme={theme.palette.mode} />
}

export default BgAnim