import { GithubAuthProvider, GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import AuthManager from "./AuthManager";

const OAuthManager = {
  /**
   * 
   */
  async login (oauthProvider, defaultEmail='') {
    // https://firebase.google.com/docs/auth/web/google-signin?hl=zh
    let provider = null;
    if(oauthProvider === 'Google') {
      provider = new GoogleAuthProvider();
      // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      provider.addScope('email');
      provider.addScope('profile');
      provider.setCustomParameters({
        'login_hint': defaultEmail, //'user@example.com'
      });    
    }
    else if(oauthProvider === 'Github') {
      provider = new GithubAuthProvider();
      // https://docs.github.com/en/apps/oauth-apps/building-oauth-apps/scopes-for-oauth-apps
      // provider.addScope('read:user');
      // provider.addScope('repo');
    }
    else {
      throw new Error(`不支援的 OAuth 登入方式 (Front): ${oauthProvider}`);
    }

    const auth = getAuth();
    auth.useDeviceLanguage();
    try {
      const userCred = await signInWithPopup(auth, provider)
      let token = null;
      if(oauthProvider === 'Google') {
        const credential = GoogleAuthProvider.credentialFromResult(userCred);  // Google Access Token. You can use it to access the Google API.
        token = credential.accessToken;
        // const user = userCred.user;  // user info
        // IdP data available using getAdditionalUserInfo(result)
      }
      else if(oauthProvider === 'Github') {
        token = GithubAuthProvider.credentialFromResult(userCred).accessToken;
      }

      if(!token)
        throw new Error(`無法取得 ${oauthProvider} 登入資訊`);

      return await AuthManager.loginWithOAuthTokenAsync(oauthProvider, token);
    }
    catch (error) {
      // const email = error.customData.email;  // The email of the user's account used.
      // const credential = GoogleAuthProvider.credentialFromError(error);  // The AuthCredential type that was used.
      console.error('oauth login error', error)
      throw new Error(`${oauthProvider} OAuth 登入失敗 (${error.code})`);
    }
    
  },
}

export default OAuthManager