import { Alert, Button, CardContent, Grid, Stack, TextField } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import AuthManager from '../../managers/AuthManager';
import SimplePage from '../_template/SimplePage';
import OAuthManager from '../../managers/OAuthManager';


const Login = ({ isSignUp = false }) => {

	const [errMsg, setErrMsg] = useState("")
	const [loading, setLoading] = useState(false)

	const STR_TITLE = isSignUp ? '註冊' : '登入'
	const STR_TITLE_EN = isSignUp ? 'Register' : 'Login'

	const navigate = useNavigate();

	/* -------------------------------------------------------------------------- */

	// Formik Form
	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
		},
		onSubmit: async (values) => {
			//await new Promise((resolve) => setTimeout(resolve, 500));
			// alert(JSON.stringify(values, null, 2));
			setLoading(true);
			try {
				// console.log(result.data)
				if (isSignUp) {
					await axios.post('/api/auth/signup', values)
					alert(values.username + '註冊成功')
				}
				else {
					await AuthManager.loginAsync(values)
				}
			}
			catch (err) {
				console.error(err)
				setErrMsg(STR_TITLE + '失敗: ' + err.response.data)
			}
			setLoading(false);
		},
	})

	/* -------------------------------------------------------------------------- */

	const loginWithOAuth = async (oauthProvider) => {
		try {
			await OAuthManager.login(oauthProvider, formik.values.username);
			navigate('/user');
		}
		catch (err) {
			setErrMsg(err.message);
		}
	}

	/* -------------------------------------------------------------------------- */	

	// 已登入
	if (AuthManager.getToken() && !isSignUp)
		navigate('/user');

	/* -------------------------------------------------------------------------- */	

	return (
		<SimplePage title={STR_TITLE} titleEn={STR_TITLE_EN}>
			<LoadingScreen open={loading} />

			<Grid container>
				<Grid item xs={12} lg={8}>
					{/* <Card> */}
					{errMsg ?
						<Alert severity='error'>{errMsg}</Alert> : ''
					}
					<CardContent>
						<form onSubmit={formik.handleSubmit}>
							<Stack spacing={3}>
								<TextField
									name='username'
									label="Username"
									value={formik.values.username}
									onChange={formik.handleChange}
									error={formik.touched.username && Boolean(formik.errors.username)}
									helperText={formik.touched.username && formik.errors.username}
								/>
								<TextField
									name='password'
									label="Password"
									type='password'
									value={formik.values.password}
									onChange={formik.handleChange}
									error={formik.touched.password && Boolean(formik.errors.password)}
									helperText={formik.touched.password && formik.errors.password}
								/>
								<Button variant="contained" size='large' type='submit'>{STR_TITLE_EN}</Button>
							</Stack>
						</form>
						<Stack spacing={3} paddingTop={5}>
							<Button variant='outlined' color='secondary' size='large' onClick={async ()=>await loginWithOAuth("Google")}>Sign in with Google</Button>
							<Button variant='outlined' color='secondary' size='large' onClick={async ()=>await loginWithOAuth("Github")}>Sign in with Github</Button>
						</Stack>
					</CardContent>
					{/* </Card> */}
				</Grid>
			</Grid>

		</SimplePage>)
};

export default Login;
