import React from 'react'
import './Fork.css'


const Fork = () => {

  return (
    <section id="fork_container">
      <img id="ｆｏｒｋ" alt='ｆｏｒｋ' src="/static/ｆｏｒｋ.png" />
      <h1 id="fork_title">Fork.</h1>
    </section>
  )
}

export default Fork