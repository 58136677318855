import { Chip, Stack, Typography } from '@mui/material'
import React from 'react'
import { motion } from "framer-motion"

function AboutCard({ imageUrl, title, description, tags=[] }) {

	return (
		<motion.div
			className="skillCard"
			style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: '100%', borderRadius: 10, '--obj-show': 0 }}
			whileHover={{ scale: 1.1, backgroundSize: '120%', '--obj-show': 1 }}
			whileTap={{ scale: 0.9 }}
		>

			<Typography variant="h5">{title}</Typography>
			<Typography variant='body2' style={{ opacity: 'var(--obj-show)' }}>{description}</Typography>
			<Stack style={{ opacity: 'var(--obj-show)'}} display='flex' flexDirection='row' sx={{ overflow: 'clip'}}>
				{tags.map((tag, i) => (
					<Chip key={i} label={tag} color='secondary' size='small' variant='outlined'   />
					))}
			</Stack>

		</motion.div>
	)
}

export default AboutCard