const logotext = "JCxYIS";
const copyright = "JCxYIS 2024";

const meta = {
  title: "JCxYIS",
  description: "Hello, I'm JCxYIS!",
};

export const introData = {
  title: "Hello, I'm ",
  quotes: [
    "歡迎來到我的網站！<br />點擊下方按鈕或是右上方功能列開始瀏覽。<br />你也可以點選這個對話框，看會發生什麼。",
    "莊周夢蝶。孰為蝶？孰為我？",
    "Stay Cool, have fun!",
    "悲しい旅はこれで終わり…帰ろう、\nあなたがいるべき場所へ…",
    "這網頁是在 2021 年春節的時候弄起來的\n而這個語錄 feature 建於 2021/02/16",
    "實際在刻網站的時候\n才發現自己根本不知道網站要長怎樣...",
    "你問為什麼只有黑暗模式？\n因為我不想戴太陽眼鏡打code啊！\n(更新：現在有兩種模式了)",
    "寫完這個 feature 我就要去睡了zzz",
    "<div style='color:red'>你剛攻擊我的OJ？我的資料結構OJ？</div><div>呃...應該是吧...</div><div style='color:#a0a000'>酷耶</div><div>你大老遠跑來這裡，就因為我攻擊你的OJ？</div><div style='color:red'>我也，為此而來。</div>",
    "到底誰會在演算法期中用文言文寫 pseudo code？",
    // "這個網站是用 ASP.net core 6 + React.js 寫的 SPA 單頁應用程式",
    "/fork",
    "想不想試試在不使用 \"p\" 鍵的情況下解程式題？",
    "Object-oriented Programming,\nHappy-oriented Living.",
    "遊戲打不贏別人，只好去做遊戲欺負人了",
    "你有試過右上角的黑暗模式開關嗎？\n滿酷的，但是要小心亮度",
    "me england am bad",
    "<div style='font-size: 48.763px'>⚠️⚠️⚠️注意⚠️⚠️⚠️</div>\n感謝你的注意",
    "點擊這個框框以重新載入對話",
    "🤔\n404 Not Found",
    "你好！謝謝！小籠包！再見！",
    "喔不！可林记",
    "γνῶθι σεαυτόν",
  ],
  description: "",
  your_img_url: "", // https://images.unsplash.com/photo-1514790193030-c89d266d5a9d
};

export const aboutData = {

  avatarImage: "https://i.imgur.com/tMsPKt3.png",

  intro: {
    title: "Hello World!",
    aboutMe: "哈囉，我是 JCxYIS，是個不務正業的非典型程式設計師。平常會用 Unity 寫些小糞 game，偶爾也會做一些其他的酷東西。",
    aboutMeEn: ('Hello, I\'m JCxYIS, a non-typical programmer who likes to make small games with Unity and do other cool stuff.'),
  },

  experience: [
    {
      jobtitle: "Programming Assistant",
      where: "Tempest Digital",
      date: "2021/09 ~ 2022/06",
    },
    {
      jobtitle: "Summer Intern",
      where: "Tempest Digital",
      date: "2021/07 ~ 2021/08",
    },
    {
      jobtitle: "MIS",
      where: "IISR Lab, NCU",
      date: "2021/04 ~ 2023/02",
    },
    {
      jobtitle: "公關組長 / 程式公會長",
      where: "NCUGameCreator",
      date: "2020/08 ~ 2022/06",
    },
  ],

  experience2: [
    {
      title: "Bachelor",
      where: "國立中央大學 資訊工程學系",
      date: "(2023)",
    },
    {
      title: "",
      where: "臺北市立建國高級中學",
      date: "2019",
    },
    {
      title: "",
      where: "臺北市立永吉國民中學",
      date: "2016",
    },
    {
      title: "",
      where: "臺北市立興雅國民小學",
      date: "2013",
    },
  ],

  skillVal: [
    {
      name: "C#",
      value: 87,
    },
    {
      name: "python",
      value: 78,
    },
    {
      name: "JavaScript",
      value: 69,
    },
  ],

  skills: [{
    title: "Game Development",
    tags: ["Unity", "Phaser.js"],
    langs: ["C#", "JavaScript"],
    image: 'https://i.imgur.com/FyEjOxw.png',
    description: "Begin with phaser.js then move to Unity, I've been making games ever since I learned how to code. I've made a few games and prototypes.",
  },
  {
    title: "XR Development",
    tags: ["Unity", "vive", "Quest", "Pico"],
    langs: ["C#"],
    image: 'https://i.imgur.com/VqCO24M.png',
    description: "",
  },
  {
    title: "Backend Development",
    tags: ["ASP.net", "Django", "Flask"],
    langs: ["C#", "Python"],
    image: 'https://i.imgur.com/bVm0HVN.png',
    description: "TODO Insert intresting description here...",
  },
  {
    title: "Frontend Development",
    tags: ["React", "bootstrap"],
    langs: ["JavaScript", 'HTML', 'CSS'],
    image: 'https://i.imgur.com/SgWzmPq.png',
    description: "I'm still learning new things and trying to make my website cooler.",
  },
  {
    title: "Networking & Cloud",
    tags: ["nginx", "docker", "AWS", "Heroku", 'Firebase'],
    langs: [],
    image: 'https://i.imgur.com/09GCxG4.jpeg',
    description: "",
  },
  {
    title: "Maker",
    description: "Being a maker is fun! I have a few projects using Arduino/PlatformIO and ESP8266/ESP32.",
    tags: ["Arduino", 'RasPi'],
    langs: ["C++", "Python"],
    image: 'https://i.imgur.com/E1ujWLK.jpeg',
  },
  ],

  awards: [

  ],

}

/* -------------------------------------------------------------------------- */

// OBSOLETE: moved to db.
// export const portfolio = [
// ]

/* -------------------------------------------------------------------------- */

const contactConfig = {
  YOUR_EMAIL: "me@jcxyis.com",
  description: "",
};

export const socialprofiles = {
  github: "https://github.com/JCxYIS",
  facebook: "https://www.facebook.com/Theaccounthasbeendeleted/",
  linkedin: "https://www.linkedin.com/in/%E5%BD%A5%E5%85%A8-%E9%99%B3-468971198/",
  twitter: "https://twitter.com/jcxyis",
  email: "mailto:me@jcxyis.com"
  // youtube: "https://youtube.com",
  // twitch: "https://twitch.com",
};

/* -------------------------------------------------------------------------- */

export const version = '5.2.1 20240601'

export const changelog = [
  {
    'version': '5.2.1',
    'content': [
      'Update bg anim base',
    ]
  },
  {
    'version': '5.2.0',
    'content': [
      'Add Google/Github OAuth login method',
    ]
  },
  {
    'version': '5.1.2',
    'content': [
      'Tweaked frame color when siwtching between dark and light mode & toggling menu',
      'Updated login logic',
    ]
  },
  {
    'version': '5.1.1',
    'content': [
      'Implement cool image effects',
      'Fix color error on profile-experience section in dark mode',
    ]
  },
  {
    'version': '5.1.0',
    'content': [
      'Move the frontend host to Firebase. (AWS started charging me for ipv4 usage... and it was expensive.)',
      'As for the backend... Currently self-hosted.',
    ]
  },
  {
    'version': '5.0.3',
    'content': [
      'Portfolio: Add tags, genre, date display',
      'Fix Account permission issue',
    ]
  },
  {
    'version': '5.0.2',
    'content': [
      'FileUpload: Add another DL option (Reverse Proxy)',
      'FileUpload: Add Delete function',
    ]
  },
  {
    'version': '5.0.1',
    'content': [
      'Fix CORS issues',
      'Fix Api endpoints failed to connect',
      'Fix drive upload',
    ]
  },
  {
    'version': '5.0.0',
    'content': [
      'Migrate server host to AWS',
      'Upgrade ASP.net core version to 8',
      'Rewrite server orchestration design',
    ]
  }
]


export {
  contactConfig, copyright, logotext, meta
};

