import { Backdrop } from '@mui/material'
import React from 'react'
import Lottie from "lottie-react";
import loadingLottieJson from '../app/lottie_json/124534-tricube-spinner-1.json';

const LoadingScreen = ({open=true}) => {
  return (
    <Backdrop open={open} sx={{flexDirection: 'column'}}>
      <Lottie animationData={loadingLottieJson} autoplay={true} loop={open} />
      <br />
      <span>Now Loading...</span>
    </Backdrop>
  )
}

export default LoadingScreen