import React, { useEffect, useState } from "react";
import "./Portfolio.css";
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from "@mui/material";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import LoadingScreen from "../../components/LoadingScreen";
import SimplePage from "../_template/SimplePage";
import PortfolioItemDetail from "./PortfolioItemDetail";
import Image from "../../components/Image/image";

export const Portfolio = () => {
  // const [modalContent, setModalContent] = React.useState(null);
  const [data, setData] = useState()
  const [dataSingle, setDataSingle] = useState()
  const navigate = useNavigate()

  const getData = async () => {
    try {
      const result = await axios.get('/api/portfolio')
      setData(result.data?.sort((t1, t2) => t2.Index > t1.Index))
    }
    catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getData()
  }, [])


  return (
    <SimplePage title="作品集" titleEn="Portfolio" desc="A collection of (some of) my works">
      <LoadingScreen open={data === undefined} />

      {/* Create new button */}
      <Grid container >
        <Button
          className='portfolio-item-closebutton'
          onClick={() => { navigate('edit') }}
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{ marginBottom: 2 }}
        >Create new</Button>
      </Grid>

      {/* Items */}
      <div className="mb-5 po_items_ho">
        {/* remote get data */}
        {data ? data.map((data, i) => {
          return (
            <div key={data.id} className="po_item" style={{ "--var": i, display: 'flex', alignItems: 'center' }}>
              <Image 
                src={data.previewImageUrl ? data.previewImageUrl : '/noimage.png'} 
                alt={"Preview pic of " + data.title} 
              />
              {/* <img src={data.previewImageUrl ? data.previewImageUrl : '/noimage.png'} alt={"Preview pic of " + data.title} loading="lazy" /> */}
              <div className="content">
                <p>{data.title}</p>
                <button onClick={() => setDataSingle(data)}>Learn More</button>
                {/* navigate(`/portfolio/${data.Id}` */}
              </div>
            </div>
          );
        }) : ''}
      </div>

      <PortfolioItemDetail data={dataSingle} setData={setDataSingle} />

    </SimplePage >
  );
};
